<template>
  <div class="p-5 font-size-30">
    <div class="font-weight-bold mb-3">Registration request is on review.</div>
    <!--<div>Registration request is on review</div>-->
    <div class="font-weight-bold font-size-70 mb-1">Thank you.</div>
  </div>
</template>

<script>
export default {
  name: 'register-pending',
}
</script>

<style scoped>

</style>
